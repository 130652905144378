import router from '@/router/index.js'
import store from '@/store'
import storage from '@/utils/storage.js'

let title = ''
router.beforeEach((to, from, next) => {
	title = to.meta.title
	if (to.path === '/') {  
		next()
	}else if(to.path === '/modifyPsw'){
		next()
	}else if(to.path === '/bindPhone'){
		next()
	}else {
		//页面被刷新
		if (store.getters.isRefresh === true) {
			store.dispatch('getMenuList').then(() => {
				store.commit("SET_REFRESH", false)
				let cachedRouterTabs = store.getters.cachedRouterTabs
				if(store.getters.currentRole == 0){
					cachedRouterTabs.push({path:'/home',title:'首页'})
				}else{
					cachedRouterTabs.push({path:'/agentHome',title:'欢迎使用'})
				}
				let cachedRouterPath = {current:null,paths:cachedRouterTabs}
				store.commit('SET_CACHED_ROUTER',cachedRouterPath)
				next({ ...to,
					replace: true
				})
			})
			store.dispatch('getDictGroups')
			store.dispatch("getCaptalStatus")
		} else { //正常跳转
			if (to.meta.isMenu !== false) {
				let cachedRouterTabs = store.getters.cachedRouterTabs
				let existCachedRouter = false
				cachedRouterTabs.forEach(item=>{
					if (item.path == to.path) {
						existCachedRouter = true
						return true
					}
				})
				if (!existCachedRouter) {
					cachedRouterTabs.push({path:to.path,title:to.meta.title,name:to.name})
				}
				let current = to.path
				let cachedRouterPath = {current:current,paths:cachedRouterTabs}
				store.commit('SET_CACHED_ROUTER',cachedRouterPath)
				store.commit('ADD_CACHED_VIEW',to)
			}
			next()
		}

		if(store.getters.currentRole!=0){
			store.dispatch('getMsgStatus')
		}
	}
})

router.afterEach((to,from) => {
	// window.document.title = title
	window.document.title = '流量平台'
})

// router.onError((error) => {
// 	console.log(error,67676)
// 	const pattern = /Loading chunk (\d)+ failed/g;
// 	const isChunkLoadFailed = error.message.match(pattern);
// 	const targetPath = router.history.pending.fullPath;
// 	if (isChunkLoadFailed) {
// 	  router.replace(targetPath);
// 	}
//   });

import {Layout} from '@/layout'

let routers = [
    {
        path: '/equipmentManagement',
        component:Layout,
        children: [
            {
                path: 'operationsManagement',
                meta: {
                isMenu:true,
                title: '操作管理',
                },
                component: () => import('@/views/equipmentManagement/deviceAssignment/operationsManagement.vue')
            },
            {
                path: 'association',
                meta: {
                isMenu:true,
                title: '限速关联',
                },
                component: () => import('@/views/equipmentManagement/speedlimitBox/association.vue')
            },
            {
                path: 'managementDetails',
                meta: {
                isMenu:true,
                title: '二维码关联',
                },
                component: () => import('@/views/equipmentManagement/qrcode/managementDetails.vue')
            },
            {
                path: 'screenDetails',
                meta: {
                isMenu:true,
                title: '屏幕管理关联',
                },
                component: () => import('@/views/equipmentManagement/screenSetting/screenDetails.vue')
            }
        ]
    },
]
// let routers = [
//                 {
//                     path: '/deviceAssignment',
//                     component:Layout,
//                     children: [
//                         {
//                             path: 'operationsManagement',
//                             meta: {
//                             isMenu:true,
//                             title: '操作管理',
//                             },
//                             component: () => import('@/views/equipmentManagement/deviceAssignment/operationsManagement.vue')
//                         },
//                     ]
//                 },
//                 {
//                     path: '/speedlimitBox',
//                     component:Layout,
//                     children: [
//                         {
//                             path: 'association',
//                             meta: {
//                             isMenu:true,
//                             title: '限速关联',
//                             },
//                             component: () => import('@/views/equipmentManagement/speedlimitBox/association.vue')
//                         },
//                     ]
//                 },
//                 {
//                     path: '/qrcode',
//                     component:Layout,
//                     children: [
//                         {
//                             path: 'managementDetails',
//                             meta: {
//                             isMenu:true,
//                             title: '二维码关联',
//                             },
//                             component: () => import('@/views/equipmentManagement/qrcode/managementDetails.vue')
//                         },
//                     ]
//                 },
//                 {
//                     path: '/screenSetting',
//                     component:Layout,
//                     children: [
//                         {
//                             path: 'screenDetails',
//                             meta: {
//                             isMenu:true,
//                             title: '屏幕管理关联',
//                             },
//                             component: () => import('@/views/equipmentManagement/screenSetting/screenDetails.vue')
//                         },
//                     ]
//                 },
                
//             ]

export default routers
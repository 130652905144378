import axios from 'axios'
import {
    Notification,
    Message,
    MessageBox
} from 'element-ui'
// import store from '../store/index.js'
import store from '@/store'

import qs from 'qs'
import loading from '../utils/Loading.js'
import {
    getToken
} from '../utils/auth.js'
import errorCode from '../utils/errorCode.js'
import storage from "../utils/storage.js"
import router from '@/router'

export  let apiHost = ''
if (process.env.NODE_ENV === 'development') {
    // apiHost = 'https://iot.sunshineiot.net/traffic-web/'   //xianshang
    apiHost = 'https://iottest.sunshineiot.net/traffic-web/'
    // apiHost = 'http://localhost/daoliang/'
        // apiHost = 'https://iottest2.sunshineiot.net/traffic-web/'
        // apiHost = 'http://10.27.100.77/traffic-web/'
        //  apiHost = 'http://localhost/wenyan/'
        //  apiHost = 'http://test3.sunshineiot.net/'

} else {
    apiHost = '/traffic-web/'
        // apiHost = 'https://iot.sunshineiot.net/traffic-web/'
}


export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
      const value = params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              var subPart = encodeURIComponent(params) + "=";
              result += subPart + encodeURIComponent(value[key]) + "&";
            }
          }
        } else {
          result += part + encodeURIComponent(value) + "&";
        }
      }
    }
    return result
  }

export var host = 'https://iot.sunshineiot.net/traffic-web/'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

let service = axios.create({
        baseURL: apiHost,
        timeout: 60000,
        withCredentials: true,
        transformRequest: [function(data, headers) {
            if (!headers["Content-Type"]) {
                return qs.stringify(data);
            }
            return data
        }],
    })
    // request拦截器
service.interceptors.request.use(config => {
    if (config.method === 'get' && config.params) {
        let url = config.url + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
      }
      return config
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(res => {
        // system_result_key
        loading.close()
        const key = parseInt(res.data.app_result_key) || parseInt(res.data.system_result_key) || 0;
        const code = parseInt(res.data.code) || 0;
        const msg = res.data.msg || res.data.system_result_message_key || res.data.validateResultStr
        if (code == 0 && key == 0) {
            if (typeof res.data.data != 'undefined') {
                if (res.data.isVipAgent && res.data.isVipAgent != 'undefined') {
                    res.data.data.isVipAgent = res.data.isVipAgent
                }
                // if(res.data.system_result_key && res.data.system_result_key != 'undefined'){
                // 	res.data.data.system_result_key = res.data.system_result_key
                // }
                return res.data.data
            }
            return res.data

        } else if (code == 401) {
            MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                location.href = '/';
            }).catch(() => {});
            return Promise.reject()
        } else if (res.data.system_error_code == 11013) {
            MessageBox.alert(msg, '提示', {
                confirmButtonText: "确定",
                callback: (action) => {
                    router.replace("/modifyPsw").catch((err) => {
                        console.log(err);
                        router.replace("/modifyPsw");
                    });
                }
            })
        } else if (res.data.system_error_code == 11014) {
            MessageBox.alert(msg, '提示', {
                confirmButtonText: "确定",
                callback: (action) => {
                    router.push("/bindPhone").catch((err) => {
                        console.log(err);
                        router.push("/bindPhone");
                    });
                }
            })
        } else {
			console.log("99991",res.data.data)
			if(res.data.data== 1013||res.data.data== 1014){
				return res.data
			}
            MessageBox.alert(msg, '提示')
            console.log(Promise.reject(new Error(msg)),111111111122222)
            return Promise.reject(new Error(msg))
        }
    },
    error => {
        loading.close()
        let {
            message
        } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)



export default service